import React from 'react'
import { motion } from 'framer-motion'

interface DiamondProps {}

const Diamond: React.FC<DiamondProps> = () => {
  const diamondAnimation = {
    rotate: [45, 135],
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
      className="overflow-visible"
    >
      <motion.rect
        width="13.373"
        height="13.373"
        x="13.143"
        y="3.686"
        stroke="#CF4951"
        strokeWidth="4"
        rx="2"
        transform="rotate(45 13.143 3.686)"
        animate={diamondAnimation}
        transition={{ yoyo: Infinity }}
      />
    </svg>
  )
}

export default Diamond
